import React from "react"
import Template from "../../layouts/base"
import { Button, Col, Form, Row, Select, Table, Typography } from "antd"

import LocalizedAniLink from "../../components/localizedAniLink"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import moment from "moment-timezone"
import { useIntl } from "gatsby-plugin-intl"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import useStateObject from "../../hooks/useStateObject"
import detailUtils from "../../utils/dictionary/detail-utils"

const { Text } = Typography

const DictionaryDetail = ({ location }) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    versionSelected: detailUtils.initVersionSelected(location.state),
    dictionary: detailUtils.initDictionary(location.state),
    buttonTitleRemoveDict: detailUtils.initButtonTitleRemoveDict(
      location.state,
      intl
    ),
    processing: false,
  })

  const createdAt = state.versionSelected.createdAt
    ? moment(state.versionSelected.createdAt, moment.ISO_8601, true).isValid()
      ? moment(state.versionSelected.createdAt).format("DD/MM/YYYY HH:mm")
      : state.versionSelected.createdAt
    : moment(new Date().toISOString()).format("DD/MM/YYYY HH:mm")

  return (
    <Template selected={["dictionary", "dictionary-detail"]}>
      <CustomBreadcrumb
        crumbs={[
          {
            label: intl.formatMessage({
              id: "menu-dictionary-management",
            }),
            link: "/dictionary",
          },
          intl.formatMessage({ id: "menu-dictionary-detail" }),
        ]}
      />
      <Row>
        <Col className="intro">
          <h1>{intl.formatMessage({ id: "menu-dictionary-detail" })}</h1>
        </Col>
      </Row>
      <div className="content">
        <Row type="flex" justify="center" style={{ alignItems: "center" }}>
          <Col span={12}>
            <h2>{state.dictionary.name}</h2>
          </Col>
          <Col span={8} offset={1} style={{ textAlign: "right" }}>
            <Text
              style={{ fontStyle: "italic", marginRight: "10px" }}
              type="secondary"
            >
              {intl.formatMessage({ id: "created-at" }) + " " + createdAt}
            </Text>
          </Col>
          <Col span={3}>
            <Select
              name="versions"
              value={state.versionSelected.version}
              onChange={detailUtils.selectVersion({ state, setState })}
              style={{ width: "100%" }}
            >
              {state.dictionary.versions.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {" "}
                  {p.version}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{state.versionSelected.description}</p>
          </Col>
        </Row>
        <Row type="flex" align="top" style={{ marginTop: 36 }}>
          <Col span={24}>
            <Table
              className="ingests-table"
              size="small"
              rowKey="name"
              columns={detailUtils.columnsDict({ intl })}
              dataSource={state.versionSelected.fields}
              locale={{
                filterTitle: intl.formatMessage({ id: "filters" }),
                filterConfirm: intl.formatMessage({ id: "apply" }),
                filterReset: intl.formatMessage({ id: "reset" }),
                emptyText: intl.formatMessage({ id: "no-data" }),
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col span={12} style={{ display: "flex" }}>
            {checkPermissions(["dictionary:write"]) ? (
              <Form.Item>
                <LocalizedAniLink
                  to="/dictionary/form"
                  state={{
                    dictionary: state.versionSelected,
                    versions: state.dictionary.versions,
                    isVersionForm: true,
                  }}
                >
                  <Button type="primary">
                    {intl.formatMessage({
                      id: "dictionary-version-create-text-button",
                    })}
                  </Button>
                </LocalizedAniLink>
              </Form.Item>
            ) : null}
            {checkPermissions(["dictionary:delete"]) ? (
              <Form.Item style={{ marginLeft: 15 }}>
                <Button
                  type="primary"
                  loading={state.processing}
                  onClick={() =>
                    detailUtils.removeVersion({
                      state,
                      intl,
                      setState,
                      record: state.versionSelected,
                    })
                  }
                >
                  {state.buttonTitleRemoveDict}
                </Button>
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      </div>
    </Template>
  )
}

export default withAuth(DictionaryDetail)
