import { message, Modal } from "antd"
import { navigate } from "gatsby-plugin-intl"
import authFetch from "../../services/network"

const { confirm } = Modal

const initVersionSelected = state => {
  if (state && "dictionary" in state && state.dictionary.versions.length > 0) {
    return state.dictionary.versions[state.dictionary.versions.length - 1]
  } else {
    return {
      id: "",
      name: "",
      description: "",
      createdAt: "",
      versions: [],
      fields: [],
    }
  }
}

const initDictionary = state => {
  if (state && state.dictionary) {
    return state.dictionary
  } else {
    return {
      id: "",
      name: "",
      description: "",
      createdAt: "",
      versions: [],
      fields: [],
    }
  }
}

const initButtonTitleRemoveDict = (state, intl) => {
  if (state && state.dictionary && state.dictionary.versions.length > 1) {
    return intl.formatMessage({ id: "dictionary-version-delete-text-button" })
  } else {
    return intl.formatMessage({ id: "dictionary-delete-text-button" })
  }
}

const selectVersion = ({ state, setState }) => versionId => {
  const version = state.dictionary.versions.find(
    version => version.id === versionId
  )
  setState({
    versionSelected: version,
  })
}

const callbackDeleteVersionOk = ({ response, setState, intl, state }) => {
  setState({ processing: false })
  if (200 !== response.status) {
    response.json().then(response => {
      if (response && response.message && response.message.length > 0) {
        message.error(response.message)
        throw `${response.message}`
      }
      return message.error(
        intl.formatMessage(
          { id: "dictionary-delete-error" },
          {
            name:
              state.dictionary.versions.length > 1
                ? intl.formatMessage({
                    id: "dictionary-version-entity",
                  })
                : intl.formatMessage({ id: "dictionary-entity" }),
          }
        )
      )
    })
  } else {
    // remove version of list
    const newListVersions = state.dictionary.versions.filter(
      version => version.id !== state.versionSelected.id
    )
    // select the next version (if there) to display
    if (newListVersions.length === 0) {
      // No there dictionary, return to list
      message.success(intl.formatMessage({ id: "dictionary-delete-ok" }))
      navigate("/dictionary")
    } else {
      // select the last version
      const newVersionSelected = newListVersions[newListVersions.length - 1]
      const nameButton =
        newListVersions.length === 1
          ? intl.formatMessage({
              id: "dictionary-delete-text-button",
            })
          : intl.formatMessage({
              id: "dictionary-version-delete-text-button",
            })
      setState({
        versionSelected: newVersionSelected,
        dictionary: {
          id: newVersionSelected.id,
          name: newVersionSelected.name,
          description: newVersionSelected.description,
          createdAt: newVersionSelected.createdAt,
          fields: newVersionSelected.fields,
          countVersions: newListVersions.length,
          versions: newListVersions,
        },
        buttonTitleRemoveDict: nameButton,
      })
      message.success(
        intl.formatMessage({ id: "dictionary-version-delete-ok" })
      )
    }
  }
}

const callbackDeleteVersionKo = ({ state, setState, intl }) => () => {
  setState({ processing: false })
  return message.error(
    intl.formatMessage(
      { id: "dictionary-delete-error" },
      {
        name:
          state.dictionary.versions.length > 1
            ? intl.formatMessage({
                id: "dictionary-version-entity",
              })
            : intl.formatMessage({ id: "dictionary-entity" }),
      }
    )
  )
}

const removeVersion = ({ state, intl, setState, record }) => {
  const uri = `${process.env.GATSBY_CONF_API_URL}/dictionaries/${record.name}:${record.version}`
  // check if is the unique version
  let text
  if (state.dictionary.versions.length === 1) {
    // unique, show alert to remove Dictionary
    text = intl.formatMessage({ id: "dictionary-delete-text-alert" })
  } else {
    // text = `Vas a borrar la versión ${state.versionSelected.version} del diccionario ${state.versionSelected.name}.`
    text = intl.formatMessage(
      {
        id: "dictionary-version-delete-text-alert",
      },
      {
        version: state.versionSelected.version,
        name: state.versionSelected.name,
      }
    )
  }
  confirm({
    title: intl.formatMessage({ id: "modal-are-you-sure" }),
    content: text,
    okText: intl.formatMessage({ id: "modal-ok" }),
    okType: "danger",
    cancelText: intl.formatMessage({ id: "modal-ko" }),
    onOk() {
      setState({ processing: true })
      authFetch(uri, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response =>
          callbackDeleteVersionOk({ response, state, setState, intl })
        )
        .catch(callbackDeleteVersionKo({ state, setState, intl }))
    },
    onCancel() {
      message.info(intl.formatMessage({ id: "modal-delete-cancel" }))
    },
  })
}

const columnsDict = ({ intl }) => [
  {
    title: intl.formatMessage({ id: "key" }),
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: intl.formatMessage({ id: "description" }),
    dataIndex: "description",
    key: "description",
    sorter: (a, b) =>
      a.description < b.description
        ? -1
        : a.description > b.description
        ? 1
        : 0,
    sortDirections: ["ascend", "descend"],
  },
  {
    title: intl.formatMessage({ id: "type" }),
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: intl.formatMessage({ id: "property" }),
    dataIndex: "property",
    key: "property",
    sorter: (a, b) =>
      a.property < b.property ? -1 : a.property > b.property ? 1 : 0,
    sortDirections: ["ascend", "descend"],
  },
]

const detailUtils = {
  initVersionSelected,
  initDictionary,
  initButtonTitleRemoveDict,
  selectVersion,
  removeVersion,
  columnsDict,
}

export default detailUtils
